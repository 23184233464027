import React from "react";
import SEO from "../components/seo";
import NotFound from "@scenes/notFound";

export default () => {
  return (
    <>
      <SEO title={"404"} />
      <NotFound />
    </>
  );
};
