import React from "react";
import { Link } from "gatsby";
import styles from "./NotFound.module.scss";

const NotFound = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Hmmm... that doesn't seem to be here.</h1>
      <Link to={"/"} className={styles.link}>
        Back to safety
      </Link>
    </div>
  );
};

export default NotFound;
